<!-- 智囊团对话 -->
<template>
  <div class='commonChatContent'>
    <div class="mainDialogBox" id="chatRecordsBox">
      <div class="brianDetialBox">
        <div class="leftPhoto">
          <img :src="brainDetial.head_photo" alt="">
        </div>
        <div class="rightDetial">
          <div class="topName">
            {{ brainDetial.staff_name }}
          </div>
          <div class="bottomDescribe">
            {{ brainDetial.duty_desc }}
          </div>
        </div>
      </div>
      <div class="dialogItem" v-for="(item) in mainDialogList" :key="item._id">
          <dialogComponent :dialogDetial="item" @handelNewQuestion="decisionProblem" @handelNewAnswer="decisionProblem" ref="dialogBox"  :changTyping="changTypingState">
        </dialogComponent>
        </div>
    </div>
    <div class="clueBox">
      <div class="innerclueBox">
        <span> 你可以尝试问: </span>
        <span class="clueItem" @click="defaultBrainContext=brainDetial.marked_words">{{brainDetial.marked_words  }}
        </span>
      </div>
    </div>

    <div class="bottomInput" >
      <inputBox @continueChat="addNewCommonDialog" @changeType="catchQuestionType" :changTyping="changTypingState" :defaultContent="defaultBrainContext" :showStopBtn="mainCease" @stopAnsweing="stopAnswer">
      </inputBox>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import inputBox from '../../components/inputBox/index.vue'
import dialogComponent from '../dialogComponents/index.vue'
import { getQuestionType, getPicAnswer, getDialogDetial, addNewQuestion, editOldQuestion, getPhotoType, photoDescribephoto, photoGetPhoto, translationTozh, getBrainHelpDetail, editeDialogTitle, getVideoAnswer, getQuestionList, getGTPStringAnswer, getAttenceDetials, getCarInOutRecord } from '@/api/juewuApi.js'
import { addTimeOrCount } from '@/api/billingProject'
import { addParking } from '@/api/outParking.js'
export default {
  name: 'commonChatModel',
  components: { inputBox, dialogComponent },
  props: {},
  data () {
    return {
      originDialog: {}, // 新建对话时传递来的数据
      isFirstNew: true, // 是否是新增的历史对话
      mainAnsering: false, // 是否正在回答
      mainEditing: true, // 是否可编辑
      mainCease: false, // 是否停止
      questionType: 1, // 问题类型
      chatWebSocket: null, // webSOCKET
      mainDialogList: [], // 聊天内容
      stringAnswer: '', // 文字类型答案
      stringQuestion: '', // 文字类型问题
      msgTimer: null, // 文字消息定时器
      inputQuestionType: 1,
      questionAttachment: '', // 问题文件
      questionAttachmentGuuid: '', // 视屏文件guuid
      dialogId: '',
      changTypingState: true, // 可以输入的状态
      stopRecord: false, // 切换房间停止记录答案
      editOldItem: '', // 传递过来的旧问题数据
      editOldAnswerIndex: 0, // 旧答案在数组中的位置
      editOldIndex: 0, // 旧问题在数组中的位置
      changePhotoPath: '', // 转换后的base64图片
      modelWs: '', // 图生问模型
      brainDetial: {}, // 智囊团信息
      stopType: false, // 停止生成文字答案记录状态501
      defaultBrainContext: '', // 默认提示词
      presetsQuestionList: [], // 预设问题
      getQuestionTimer: '', // 获取问答库轮询定时器
      needReconnet: true
    }
  },
  watch: {
    '$route.query.id' (val, oldVal) {
      // console.log('watch-----', val)
      if (val) {
        this.reLoadData()
        this.$nextTick(() => {
          // console.log('切换房间', val)
          this.dialogId = val
          // 初始化先前的内容
          // 切换历史对话
          // 获取记录
          this.fnGetDialogDetial(val)
          this.fngetBrainHelpDetail(this.$route.query.staff_id)
        })
      }
      // window.location.reload();
    }
  },
  computed: {
    ...mapGetters(['getUserInfo'])
  },
  methods: {
    // 0,获取历史对话内容
    async fnGetDialogDetial (id) {
      this.mainDialogList = []
      const res = await getDialogDetial({ dialogu_id: id })
      // console.log('历史对话', res)
      if (res && res.Data.length > 0) {
        res.Data.forEach(item => {
          this.mainDialogList.unshift(item)
        })
        // this.mainDialogList = res.Data
        this.$nextTick(() => {
          this.fnscrollTop()
        })
      }
    },
    // 1, 获取输入框内容
    addNewCommonDialog (msg, type) {
      console.log('continue', msg, type)
      this.stopRecord = false
      this.decisionProblem(msg, '', type)
      setTimeout(() => {
        this.fnscrollTop()
      }, 500)
    },
    // 链接webSocket()
    initWebSocket () {
      const that = this
      if (typeof WebSocket === 'undefined') {
        this.$notify({
          title: '提示',
          message: '当前浏览器无法接收实时信息，请使用其他浏览器！',
          type: 'warning',
          position: 'bottom-right',
          duration: 0
        })
      } else {
        // var wsUri = 'wss://visionmiracle.cn/ws?BelongingGroup=1&SendOrReceive=1'
        var wsUri = 'wss://tcity.lebopark.com/T2T?BelongingGroup=1&SendOrReceive=1 '
        // var wsUri = "ws://188.128.0.25:1000/ws?BelongingGroup=1&SendOrReceive=1";
        this.chatWebSocket = new WebSocket(wsUri)
        this.chatWebSocket.onopen = function () {
          console.log('现在可以提问了')
          that.changTypingState = true
          // console.log(' that.changTypingState ', that.changTypingState)
        }
        this.chatWebSocket.onclose = function () {
          console.log('Disconnected')
          that.changTypingState = false
          that.reconnet()
        }
        this.chatWebSocket.onmessage = function (evt) {
          console.log('onmessage-----', evt.data)
          let nowAnswer = ''
          let questionList = ''
          let answerList = ''
          let answerObj = ''
          that.mainCease = true
          that.stringAnswer = that.stringAnswer + evt.data
          if (that.stringAnswer) {
            if (that.editOldItem) {
              // 编辑旧问题数据 editOldIndex
              // console.log('editOldItem editOldIndex', that.editOldItem)
              nowAnswer = that.mainDialogList[that.editOldIndex]
              // console.log('nowAnswer', nowAnswer)
              // 更具id查找是哪一项问题
              questionList = nowAnswer.question_answer
              // 找到问题项中的答案项
              answerList = questionList[questionList.length - 1].answer_data
              // 转换为字符串
              answerObj = { textName: that.stringAnswer }
              answerList[answerList.length - 1].answer_content = JSON.stringify(answerObj)
              answerList[answerList.length - 1].answer_type = 1
            } else {
            // 获取新数据
              that.addDialogItem(that.stringQuestion, '', 1)
              //   找到是哪一个问题 --更具id查找是哪一项
              nowAnswer = that.mainDialogList[that.mainDialogList.length - 1]
              questionList = nowAnswer.question_answer
              // 找到是问题中的哪一个答案 --待修改为根据id查找是答案的哪一项
              answerList = questionList[questionList.length - 1].answer_data
              // 转换为字符串
              answerObj = { textName: that.stringAnswer }
              answerList[answerList.length - 1].answer_content = JSON.stringify(answerObj)
              that.fnscrollTop()
            }
            that.fnDebounce()
          }
          //   that.debunceEnd()
        }
        this.chatWebSocket.onerror = function (evt) {
          console.log('Error occured: ' + evt.data)
        }
      }
    },
    // 重新连接 WebSocket
    reconnet () {
      if (this.needReconnet) {
        this.initWebSocket()
      }
    },
    // 1.2,判断问题类型
    async decisionProblem (item, oldQuestion, switchQuestionType) {
      if (!item.question) return
      console.log('item', item)
      console.log('old', oldQuestion)
      console.log('switchQuestionType', switchQuestionType)
      // console.log('this.mainDialogList', this.mainDialogList)
      this.changTypingState = false
      this.inputQuestionType = switchQuestionType
      this.stopRecord = false
      this.stringQuestion = ''
      this.questionAttachment = ''
      this.questionAttachmentGuuid = ''
      this.stringQuestion = item.question
      this.questionAttachment = item.questionFile
      this.questionAttachmentGuuid = item.questionFileGuuid
      if (oldQuestion) {
        this.editOldItem = oldQuestion
        const sameQuestoinIndex = this.mainDialogList.findIndex(item => {
          // 查询旧对话在列表中的第几项
          return item._id === oldQuestion.mainRoomId
        })
        this.editOldIndex = sameQuestoinIndex
        // console.log('sameQuestoinIndex', sameQuestoinIndex)
        // console.log(' this.mainDialogList[sameQuestoinIndex]', this.mainDialogList[sameQuestoinIndex])
        if (oldQuestion.reGetAnswer) {
          // 找到当前问题是在数组中的哪一项
          const sameAnswerIndex = this.mainDialogList[sameQuestoinIndex].question_answer.findIndex(ansitem => {
            return ansitem.question_id === oldQuestion.question_id
          })
          this.editOldAnswerIndex = sameAnswerIndex
          // console.log('sameAnswerIndex', sameAnswerIndex)
          // 重新生成答案
          // 给当前问题的答案添加一项空答案
          this.mainDialogList[sameQuestoinIndex].question_answer[sameAnswerIndex].answer_data.push({
            answer_content: JSON.stringify({ textName: '' }),
            answer_type: ''
          })
          // 将答案页翻到最后
          this.$refs.dialogBox[this.editOldIndex].goTotal(2)
        } else {
          // 重新提问
          // 给旧数据添加一项空数据
          this.editOldAnswerIndex = ''
          this.mainDialogList[sameQuestoinIndex].question_answer.push({
            question_name: item.question,
            question_attachment: item.questionFile,
            question_type: switchQuestionType,
            answer_data: [{
              answer_content: JSON.stringify({ textName: '' }),
              answer_type: ''
            }]
          })
          // 添加完成后自动翻页到最后一项
          // console.log('this.mainDialogList[sameQuestoinIndex]._id', this.mainDialogList[sameQuestoinIndex]._id)
          this.$nextTick(() => {
            this.$refs.dialogBox[this.editOldIndex].goTotal(1)
          })
        }
      } else {
        this.editOldIndex = ''
        this.editOldItem = ''
        // 新问题则直接在后面添加一项
        this.addDialogItem(item.question, 'anwering', '')
      }
      // this.fnGetPhotoType(question)
      if (switchQuestionType === 1 || switchQuestionType === 501) {
        // 判断问题类型
        const res = await getQuestionType({ question: item.question })
        if (res && res.code === 200) {
          console.log('判断问题类型', res)
          const type = res.data.name
          console.log('问题类型', type)
          if (type === 'question_answers') {
            // 调用文字答案
            this.getAnswer(item.question)
          } else if (type === 'get_checking-in') {
            // 调用考勤查询
            this.getAttendanceRecords(item.question, res.data.arguments)
          } else if (type === 'get_vehicle_trajectory') {
            // 调用车辆轨迹
            this.getCarTracks(item.question, res.data.arguments)
          } else if (type === 'create_parking_lot') {
            // 创建停车场
            this.addNewOutPark(item.question, res.data.arguments)
          } else if (type === 'create_charging_rules') {
            // 创建收费规则
            this.addNewCharges(item.question, res.data.arguments)
          } else {
            // 调用文字答案
            this.getAnswer(item.question)
          }
        } else {
          this.getAnswer(item.question)
        }
      } else if (switchQuestionType === 2) {
        // 调用文生图
        this.fnGetPicAnswer(item.question, oldQuestion)
      } else if (switchQuestionType === 3) {
        // 调用图生文
        this.getWordFromPhoto(item.question, item.questionFile)
      } else if (switchQuestionType === 4) {
        // 调用图生图
        this.fnPhotoDescribephoto(item.question, item.questionFile)
      } else if (switchQuestionType === 5) {
        // 调用图绘图
        this.fnPhotoGetPhoto(item.question, item.questionFile)
      } else if (switchQuestionType === 6) {
        // 调用视屏分析
        this.fngetVideoAnswer(item.questionFileGuuid, item.question, item.questionFile, 0)
      } else {

      }
      // if (switchQuestionType === 1) {
      // } else {
      //   // 判断问题类型
      //   const res = await getQuestionType({ question })
      //   if (res && res.code === 200) {
      //     const type = JSON.parse(res.data.arguments).problem_type
      //     //   console.log('问题类型',type)
      //     if (type === '文本回答') {
      //     // 调用文字答案
      //       this.getAnswer(question)
      //     } else if (type === '图片绘制') {
      //     // 调用图片答案
      //       this.fnGetPicAnswer(question, oldQuestion)
      //     }
      //   } else {
      //   // 判断失败调用文字问答
      //     this.getAnswer(question)
      //   }
      // }
    },
    // 1.3.1,获取文字类型问题答案
    getAnswer (question) {
      const presetAnswers = this.selectAnswer(question)
      if (presetAnswers) {
        // 处理预设问题
        this.processPresetAnswers(presetAnswers)
      } else {
        const option = {}
        option.model = 'text-davinci-003'
        option.temperature = 0.9
        option.max_tokens = 2048
        option.top_p = 1
        option.frequency_penalty = 0.0
        option.presence_penalty = 0.6
        option.prompt = question
        if (this.chatWebSocket.readyState !== 1) {
          console.log('this.chatWebSocket.readyState', this.chatWebSocket.readyState)
          this.initWebSocket()
        } else if (this.chatWebSocket.readyState === 1) {
          this.chatWebSocket.send(JSON.stringify(option))
        // this.mainCease = true
        }
      }

      //
      //   this.debunceEnd()
    },
    // 1.3.2,获取图片答案
    async fnGetPicAnswer (question, oldQuestion) {
      // this.mainCease = true
      const res = await getPicAnswer({ question })
      if (res && res.code === 200) {
        const picAnswerPath = JSON.parse(res.data).data[0].FilePath
        this.addDialogItem(question, picAnswerPath, 2)
      } else {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      }
    },
    // 1.3.3 获取图生文
    getWordFromPhoto (question, file) {
      // 方法调用  this.src:图片地址
      const filePath = JSON.parse(file).question_file
      this.convertImageToBase64(filePath, this.base64CallBack, question)
    },
    convertImageToBase64 (imgUrl, callback, question) {
      const image = new Image()
      image.crossOrigin = 'anonymous'
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = imgUrl + '?v=' + Date.now()
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.height = image.naturalHeight
        canvas.width = image.naturalWidth
        ctx.drawImage(image, 0, 0)
        const dataUrl = canvas.toDataURL()
        callback && callback(dataUrl, question)
      }
    },
    base64CallBack (base64, question) {
      // 图生文获取base64调用
      this.initPhotoWebSocket(question, base64)
    },
    // 1.3.4 获取图生图
    async fnPhotoDescribephoto (question, file) {
      console.log('图生图question', question)
      const filePath = JSON.parse(file).question_file
      console.log('filePath', filePath)
      const obj = {}
      obj.Question = question
      obj.ImgUrl = filePath
      const res = await photoDescribephoto(obj)
      console.log('图生图res', res)
      if (res && res.code === 200) {
        const photoDescribePhoto = JSON.parse(res.data).data[0].FilePath
        console.log('photoDescribePhoto', photoDescribePhoto)
        this.addDialogItem(question, photoDescribePhoto, 4)
      } else {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      }
    },
    // 1.3.5获取图绘图答案
    async fnPhotoGetPhoto (question, file) {
      console.log('图绘图question', question)
      const filePath = JSON.parse(file).question_file
      console.log('filePath', filePath)
      const obj = {}
      obj.Question = question
      obj.ImgUrl = filePath
      const res = await photoGetPhoto(obj)
      console.log('图绘图res', res)
      if (res && res.code === 200) {
        const photoToPhotoAnswerPath = JSON.parse(res.data).data[0].FilePath
        console.log('photoToPhotoAnswerPath', photoToPhotoAnswerPath)
        this.addDialogItem(question, photoToPhotoAnswerPath, 5)
      } else {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      }
    },
    // // 2.1 判断图片操作类型
    // async fnGetPhotoType (question) {
    //   const res = await getPhotoType({ question })
    //   console.log('操作图片res', res)
    //   if (res && res.code === 200) {
    //     const type = JSON.parse(res.data.arguments).problem_type
    //     //   console.log('问题类型',type)
    //     if (type === '图片编辑') {
    //       // 调用图片编辑
    //       console.log('图片编辑')
    //     } else if (type === '图片变异') {
    //       // 调用图片答案
    //       console.log('图片变异')
    //     } else if (type === '图片转文字') {
    //       // 调用图片答案
    //       console.log('图片转文字')
    //     }
    //   } else {
    //     // 无响应
    //   }
    // },
    // 0.4，将问题与答案添加到数组中展示
    // type类型,1=文本，2=图文，3=文档,4=数据
    addDialogItem (question, answer, type) {
      // console.log('添加type', type)
      // 粗糙处理
      //  还没有答案就先显示问题
      if (type === '') {
        this.mainDialogList.push({
          question_answer: [{
            question_name: question,
            question_attachment: this.questionAttachment ? this.questionAttachment : '',
            question_type: this.inputQuestionType,
            answer_data: [{
              is_full: false
            }]
          }],
          answer_type: ''
        })
      } else {
        if (this.editOldItem) {
          if (type === 1) {
            this.mainDialogList.splice(this.mainDialogList.length - 1, 1)
            this.mainDialogList.push({
              question_answer: [{
                question_name: question,
                question_attachment: '',
                question_type: this.inputQuestionType,
                answer_data: [{
                  answer_content: JSON.stringify({ textName: answer }),
                  answer_type: type
                }]
              }],
              answer_type: type
            })
          } else if (type === 2 || type === 3 || type === 4 || type === 5) {
            const nowAnswer = this.mainDialogList[this.editOldIndex]
            const questionList = nowAnswer.question_answer
            const answerList = questionList[questionList.length - 1].answer_data
            answerList[answerList.length - 1].answer_content = JSON.stringify({ textName: answer })
            answerList[answerList.length - 1].answer_type = type
            // 获取数据后发送新增请求
            this.editWholeDialog(answer, type, this.inputQuestionType)
          } else if (type === 500) {
            // 无回答
            const nowAnswer = this.mainDialogList[this.editOldIndex]
            const questionList = nowAnswer.question_answer
            const answerList = questionList[questionList.length - 1].answer_data
            answerList[answerList.length - 1].answer_content = JSON.stringify({ textName: answer })
            answerList[answerList.length - 1].answer_type = type
            // 获取数据后发送新增请求
            this.editWholeDialog(answer, type, this.inputQuestionType)
          }
        } else {
          // 判断问题类型，不同答案展示方式
          if (type === 1) {
            this.mainDialogList.splice(this.mainDialogList.length - 1, 1)
            this.mainDialogList.push({
              question_answer: [{
                question_name: question,
                question_attachment: '',
                question_type: this.inputQuestionType,
                answer_data: [{
                  answer_content: JSON.stringify({ textName: answer }),
                  answer_type: type
                }]
              }],
              answer_type: type
            })
          } else if (type === 2 || type === 3 || type === 4 || type === 5) {
            this.mainDialogList.splice(this.mainDialogList.length - 1, 1)
            this.mainDialogList.push({
              question_answer: [{
                question_name: question,
                question_attachment: '',
                question_type: this.inputQuestionType,
                answer_data: [{
                  answer_content: JSON.stringify({ textName: answer }),
                  answer_type: type
                }]
              }],
              answer_type: type
            })
            // 获取数据后发送新增请求
            this.addWholeDialog(answer, type, this.inputQuestionType)
          } else if (type === 6 || type === 7 || type === 8 || type === 9 || type === 10) {
            this.mainDialogList.splice(this.mainDialogList.length - 1, 1)
            this.mainDialogList.push({
              question_answer: [{
                question_name: question,
                question_attachment: '',
                question_type: type,
                answer_data: [{
                  answer_content: JSON.stringify({ textName: answer }),
                  answer_type: type
                }]
              }],
              answer_type: type
            })
            // 获取数据后发送新增请求
            this.addWholeDialog(answer, type, this.inputQuestionType)
          } else if (type === 500) {
            // 无回答
            this.mainDialogList.splice(this.mainDialogList.length - 1, 1)
            this.mainDialogList.push({
              question_answer: [{
                question_name: question,
                question_attachment: '',
                question_type: this.inputQuestionType,
                answer_data: [{
                  answer_content: JSON.stringify({ textName: answer }),
                  answer_type: type
                }]
              }],
              answer_type: 500
            })
            // 获取数据后发送新增请求
            this.addWholeDialog(answer, type, this.inputQuestionType)
          }
        }
      }
      if (!this.editOldItem) {
        this.$nextTick(() => {
          this.fnscrollTop()
        })
      }
    },
    // 0.5,add记录问题答案
    async addWholeDialog (answer, answertype, questiontype) {
      // console.log('this.stopRecord', this.stopRecord)
      // console.log('this.isFirstNew', this.isFirstNew)
      if (this.stopRecord) return
      if (this.isFirstNew || this.editOldItem) {
        this.editWholeDialog(answer, answertype, questiontype)
      } else {
        const obj = {}
        obj.is_staff = true
        obj.dialogu_id = this.dialogId
        obj.question_type = questiontype
        obj.question_name = this.stringQuestion ? this.stringQuestion : ''
        obj.question_attachment = this.questionAttachment ? this.questionAttachment : ''
        obj.answer_content = JSON.stringify({ textName: answer })
        obj.answer_time = '1970-01-01 00:00:00'
        obj.answer_type = answertype
        obj.question_answer = [{
          question_type: questiontype,
          question_name: this.stringQuestion ? this.stringQuestion : '',
          question_id: this.isFirstNew ? this.dialogId + 'firstnew' : this.dialogId + Date.now(),
          question_attachment: this.questionAttachment ? this.questionAttachment : '',
          answer_data: [{
            answer_content: JSON.stringify({ textName: answer }),
            answer_time: '1970-01-01 00:00:00',
            answer_type: answertype
          }]
        }]
        const res = await addNewQuestion(obj)
        // 添加完答案后将最后一项数据替换为请求来的数据
        if (res && res.Code === 200) {
          // console.log('res.Data', res)
          this.mainDialogList.pop()
          this.mainDialogList.push(res.Data)
          const obj = {
            _id: this.dialogId,
            dialogu_name: this.stringQuestion ? this.stringQuestion : ''
          }
          this.fngetBrainRoomList(obj)
        }
        // console.log('addaftermainDialogList', this.mainDialogList)
      }
      // this.fnGetDialogDetial(this.$route.params.id || this.$route.query.id)
      this.changTypingState = true
      this.mainCease = false
      // console.log('新增一条对话', res)
    },
    // 0.6,edilt编辑问题 获取当前问题id
    async editWholeDialog (answer, answertype, questiontype) {
      // console.log('编辑this.editOldIndex', this.mainDialogList)
      // console.log('编辑this.mainDialogList[sameQuestoinIndex]', this.mainDialogList[this.editOldIndex])
      const obj = {}
      if (this.isFirstNew) {
        obj.is_staff = true
        obj._id = this.newQuestionId
        obj.question_type = this.inputQuestionType === 1 ? answertype : this.inputQuestionType
        obj.question_type = this.inputQuestionType ? this.inputQuestionType : 1
        obj.question_name = this.stringQuestion ? this.stringQuestion : '' // 传过来的问题
        obj.question_attachment = this.questionAttachment ? this.questionAttachment : ''
        obj.answer_content = JSON.stringify({ textName: answer })
        obj.answer_time = '1970-01-01 00:00:00'
        obj.answer_type = answertype
        obj.question_answer = [{
          question_type: this.inputQuestionType === 1 ? answertype : this.inputQuestionType,
          question_name: this.stringQuestion ? this.stringQuestion : '', // 传过来的问题
          question_id: this.isFirstNew ? this.dialogId + 'firstnew' : '', // 传过来的问题id
          question_attachment: this.questionAttachment ? this.questionAttachment : '',
          answer_data: [{
            answer_content: JSON.stringify({ textName: answer }),
            answer_time: '1970-01-01 00:00:00',
            answer_type: answertype
          }]
        }]
      } else if (this.editOldItem) {
        // 编辑问题
        obj.is_staff = true
        obj._id = this.editOldItem ? this.editOldItem.mainRoomId
          : ''// 传过来的主id
        obj.question_type = this.inputQuestionType ? this.inputQuestionType : 1
        obj.question_name = this.stringQuestion ? this.stringQuestion : '' // 传过来的问题
        obj.question_attachment = this.questionAttachment ? this.questionAttachment : ''
        obj.answer_content = JSON.stringify({ textName: answer })
        obj.answer_time = '1970-01-01 00:00:00'
        obj.answer_type = answertype
        obj.question_answer = this.mainDialogList[this.editOldIndex].question_answer
        obj.question_answer[obj.question_answer.length - 1].question_id = this.editOldItem.mainRoomId + Date.now() + 'newquestion'
      }
      const res = await editOldQuestion(obj)
      if (this.isFirstNew) {
        if (res && res.Code === 200) {
          // console.log('res.Data', res)
          this.mainDialogList.pop()
          this.mainDialogList.push(res.Data)
        }
      } else {
        const foreachAnswerDatafather = this.mainDialogList[this.editOldIndex].question_answer
        console.log('foreachAnswerDatafather', this.mainDialogList[this.editOldIndex].question_answer)
        if (this.editOldAnswerIndex) {
          const foreachAnswerData = this.mainDialogList[this.editOldIndex].question_answer[this.editOldAnswerIndex].answer_data
          console.log('foreachAnswerData', foreachAnswerData)

          foreachAnswerData.forEach(item => {
            console.log('a')
            this.$set(item, 'is_full', true)
          })
        } else {
          foreachAnswerDatafather[foreachAnswerDatafather.length - 1].answer_data.forEach(faitem => {
            console.log('b')
            this.$set(faitem, 'is_full', true)
          })
        }
      }
      console.log(' this.mainDialogList', this.mainDialogList)
      this.isFirstNew = false
      this.changTypingState = true
    },
    fnDebounce () {
      if (this.msgTimer) {
        clearTimeout(this.msgTimer)
      }
      this.msgTimer = setTimeout(() => {
        console.log('接口完成-----', this.stringAnswer)
        // 记录对话传递给后台
        // this.addDialogItem(this.stringQuestion, this.stringAnswer, 1)
        if (this.stopType) {
          // 停止后的答案
          let nowAnswer = ''
          let questionList = ''
          let answerList = ''
          if (this.editOldIndex) {
            nowAnswer = this.mainDialogList[this.editOldIndex]
            console.log('nowAnswer', nowAnswer)
            // 更具id查找是哪一项问题
            questionList = nowAnswer.question_answer
            // 找到问题项中的答案项
            answerList = questionList[questionList.length - 1].answer_data
            answerList[answerList.length - 1].answer_type = 501
          } else {
            nowAnswer = this.mainDialogList[this.mainDialogList.length - 1]
            console.log('nowAnswer', nowAnswer)
            questionList = nowAnswer.question_answer
            // 找到是问题中的哪一个答案 --待修改为根据id查找是答案的哪一项
            answerList = questionList[questionList.length - 1].answer_data
            answerList[answerList.length - 1].answer_type = 501
          }
          this.addWholeDialog(this.stringAnswer, 501, 1)
        } else {
          // 完整文字答案
          this.addWholeDialog(this.stringAnswer, 1, 1)
        }
        this.stopType = false
        this.stringAnswer = ''
      }, 3000)
    },
    // 切换清空数据与状态
    reLoadData () {
      this.needReconnet = true
      this.stopRecord = true
      this.isFirstNew = false
      this.mainCease = false
      this.chatWebSocket.close(1000, '切换对话')
      // this.mainDialogList = []
    },
    // 获取输入框的问题类型
    catchQuestionType (catchType) {
      this.inputQuestionType = catchType
      console.log(' this.inputQuestionType', this.inputQuestionType)
    },
    // 保持最下面
    fnscrollTop () {
      var chatRecordsBox = document.getElementById('chatRecordsBox')
      chatRecordsBox.scrollTop = chatRecordsBox.scrollHeight
    },
    // 停止问答
    stopAnswer () {
      this.chatWebSocket.close(1000, '切换对话')
      this.reconnet()
      this.stopType = true
    },
    // 获取随机码
    get_random_str (number) {
      const x = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789'
      let str = ''
      for (let i = 0; i < number; i++) {
        // 重点  这里利用了Math.random()函数生成的随机数大于0 小于1 我们可以
        // 用它的随机数来乘以字符串的长度,得到的也是一个随机值，再通过parseInt()
        // 函数取整，这样就可以实现字符串的随机取值了
        str += x[parseInt(Math.random() * x.length)]
      }
      return str
    },
    // 文字翻译
    async fnTranslationTozh (question, msg) {
      const res = await translationTozh('', msg)
      console.log('res', JSON.parse(res.Message).result)
      var result = JSON.parse(res.Message).result
      var dst = result.trans_result[0].dst
      this.addDialogItem(question, dst, 3)
    },
    // 图生文
    initPhotoWebSocket (message, imgBase64) {
      const that = this
      const session = this.get_random_str(10)
      this.modelWs = new WebSocket('wss://uatcity.lebopark.com/imgToTxt/queue/join')
      this.modelWs.onopen = function () {
      }
      this.modelWs.onclose = function () {
      }
      this.modelWs.onerror = function (event) {
      }
      this.modelWs.onmessage = function (evt) {
        var data = JSON.parse(evt.data)

        if (data.msg === 'send_hash') {
          that.modelWs.send('{"fn_index":1,"session_hash":"' + session + '"}')
        }
        if (data.msg === 'send_data') {
          console.log('图片解析开始')
          that.modelWs.send('{"data": ["' + message + '",0.8,0.4,5,"' + imgBase64 + '",[["","Hi, What do you want to know about this image?"]],"",false],"event_data": null,"fn_index": 1,"session_hash": "' + session + '"}')
        }
        if (data.msg === 'process_completed') {
          var msg = data.output.data[1][0][1]
          console.log('图生文msg', msg)
          that.fnTranslationTozh(message, msg, 3)
        }
      }
    },
    // 视频分析
    // id=guuid quesion=问题名 file=attachment文件json maxwait=超时时间一分钟
    async fngetVideoAnswer (id, question, file, maxwait) {
      if (maxwait > 60000) {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      } else {
        const res = await getVideoAnswer({ guuid: id, user_name: this.getUserInfo.user_name })
        if (res.data && res.data.gpt_answer) {
          const videoAnswer = res.data.gpt_answer
          this.addDialogItem(question, videoAnswer, 6)
        } else {
          setTimeout(() => {
            const newWait = maxwait + 500
            this.fngetVideoAnswer(this.questionAttachmentGuuid, this.stringQuestion, this.questionAttachment, newWait)
          }, 500)
        }
      }
    },
    // 获取考勤情况分析
    async  getAttendanceRecords (question, data) {
      const stringData = JSON.parse(data)
      const attendanceDate = stringData.date
      const personName = stringData.user_name
      console.log('stringData', stringData)
      const res = await getGTPStringAnswer({ question: attendanceDate + '转换为yyyy-MM这种时间格式,只要结果' })
      if (res && res.code === 200) {
        const regex = /\d{4}-\d{2}/g // 匹配连续的数字
        const number = res.data.match(regex)
        console.log('处理过后result', number)
        if (number && personName) {
          this.fngetAttenceDetials(question, number[0], personName)
        } else {
          this.addDialogItem(question, '缺少必要参数请重试', 500)
        }
      } else {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      }
    },
    // 考情人员id查询
    async fngetAttenceDetials (question, date, name) {
      const res = await getAttenceDetials({ sDate: date, inspector_name: name })
      console.log('获取考勤数据res', res)
      if (res && res.Code === 200) {
        if (res.Data) {
          this.addDialogItem(question, res.Data, 8)
        } else {
          this.addDialogItem(question, '未查询到考勤记录', 500)
        }
      } else {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      }
    },
    // 获取车辆轨迹
    async  getCarTracks (question, data) {
      const stringData = JSON.parse(data)
      const plateNumber = stringData.license_plate_number
      const parkingName = stringData.parking_name
      const inOutDate = stringData.date
      let searchStartTime = ''
      let searchEndTime = ''
      console.log('stringData', stringData)
      if (inOutDate.indexOf('今') !== -1) {
        searchStartTime = this.$moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
        searchEndTime = this.$moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
        console.log('inOutDate', inOutDate, searchStartTime, searchEndTime)
      } else if (inOutDate.indexOf('昨') !== -1) {
        searchStartTime = this.$moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss')
        searchEndTime = this.$moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss')
        console.log('inOutDate', inOutDate, searchStartTime, searchEndTime)
      } else {
        const res = await getGTPStringAnswer({ question: inOutDate + '转换为yyyy-MM-dd这种时间格式,只要结果' })
        if (res && res.code === 200) {
          const regex = /\d{4}-\d{2}-\d{2}/g // 匹配连续的数字
          const number = res.data.match(regex)
          console.log('处理过后result', number)
          searchStartTime = this.$moment(number[0]).startOf('month').format('YYYY-MM-DD HH:mm:ss')
          searchEndTime = this.$moment(number[0]).endOf('month').format('YYYY-MM-DD HH:mm:ss')
          console.log('inOutDate', inOutDate, searchStartTime, searchEndTime)
        }
      }
      if (searchStartTime && searchEndTime && plateNumber) {
        this.getCarDetials(question, searchStartTime, searchEndTime, plateNumber)
      } else {
        this.addDialogItem(question, '缺少必要参数请重试', 500)
      }
    },
    // 获取车辆轨迹数据
    async getCarDetials (question, start, end, plate) {
      const res = await getCarInOutRecord({
        go_in_time_start: start,
        go_out_time_end: end,
        plate_number: plate
      })
      if (res && res.Code === 200) {
        console.log('行车轨迹', res)
        if (res.Data.data.length > 0) {
          this.addDialogItem(question, res.Data, 7)
        } else {
          this.addDialogItem(question, '未查询到车辆轨迹数据', 500)
        }
      } else {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      }
    },
    // 创建停车场
    addNewOutPark (question, data) {
      const stringData = JSON.parse(data)
      const parkName = stringData.parking_lot_name
      console.log('stringData', stringData)
      if (parkName) {
        const obj = {}
        obj.berth_num = '50'
        obj.parking_name = parkName
        this.fnaddParking(question, obj)
      } else {
        this.addDialogItem(question, '缺少必要参数请重试', 500)
      }
    },
    // 创建新停车场
    async fnaddParking (question, obj) {
      const res = await addParking(obj)
      if (res && res.Code === 200) {
        this.addDialogItem(question, obj, 9)
      } else {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      }
    },
    // 创建收费规则
    addNewCharges (question, data) {
      const stringData = JSON.parse(data)
      console.log('stringData', stringData)
      const maxCharge = stringData['24_hours']
      const hourCharge = stringData.One_hour
      console.log('maxCharge', maxCharge, hourCharge)
      if (maxCharge && hourCharge) {
        const obj = {}
        obj.scheme_name = '临停计时计费方案'
        obj.scheme_details = '临停计时计费方案:免费时长60分钟。此后每小时收费' + hourCharge + '元，一天最大收费' + maxCharge + '元。'
        obj.scheme_type = 1
        obj.timing_details = {
          billing_cycle: 1440,
          free_time: 60,
          free_time_iscalculate: true,
          is_quota: true,
          quota_amount: maxCharge,
          quota_duration: 1440,
          cost_time_period_timing: [
            { time_duration: 60, time_amount: hourCharge },
            { time_duration: 60, time_amount: hourCharge }
          ]
        }
        this.fnaddTimeOrCount(question, obj)
      } else {
        this.addDialogItem(question, '缺少必要参数请重试', 500)
      }
    },
    // 新建收费规则
    async fnaddTimeOrCount (question, obj) {
      const res = await addTimeOrCount(obj)
      if (res && res.Code === 200) {
        this.addDialogItem(question, obj, 10)
      } else {
        this.addDialogItem(question, '呃，网络不给力，请重试...', 500)
      }
    },
    // 获取智囊团详情
    async fngetBrainHelpDetail (id) {
      const res = await getBrainHelpDetail({ _id: id })
      console.log('智囊团详情', res)
      this.brainDetial = res.Data
    },
    // 编辑新对话名更新参谋对话列表
    async fngetBrainRoomList (obj) {
      const res = await editeDialogTitle(obj)
      this.$store.dispatch('juewuStore/fngetBrainRoomList')
    },
    // 获取预设问题
    async fnGetQuestionList () {
      this.presetsQuestionList = []
      const res = await getQuestionList()
      res.Data.forEach(item => {
        this.presetsQuestionList.push({
          q: item.keywords.split(','),
          verified_num: item.matching_number,
          a: item.answers
        })
      })
      console.log('this.presetsQuestionList', this.presetsQuestionList)
    },
    // 匹配问答库
    selectAnswer (question) {
      console.log('匹配问答库')
      for (var i = 0; i < this.presetsQuestionList.length; i++) {
        var qa = this.presetsQuestionList[i]
        var count = 0
        qa.q.forEach(q => {
          const reg = new RegExp('.*' + q.toUpperCase() + '.*') // 正则
          var bo = reg.test(question.toUpperCase()) // 去匹配待查询的字符串
          if (bo) {
            count++
          }
        })
        if (count >= qa.verified_num) {
          return qa.a[Math.floor(Math.random() * qa.a.length + 1) - 1].answersStr
        }
      }
    },
    // 处理预设问题
    processPresetAnswers (str) {
      // console.log('预设答案str', str)
      const stringAnswer = str.join(',')
      const startNum = 0
      const oneCharacters = this.lengthCutting(stringAnswer, 1)
      setTimeout(() => {
        this.addInSequence(oneCharacters, startNum, oneCharacters.length)
      }, 2000)
    },
    // 按照num切分字符串，几个字为一组
    lengthCutting (str, num) {
      const strArr = []
      for (let i = 0; i < str.length; i += num) strArr.push(str.slice(i, i + num))
      return strArr
    },
    // 逐字添加答案
    addInSequence (data, star, end) {
      let nowAnswer = ''
      let questionList = ''
      let answerList = ''
      let answerObj = ''
      this.mainCease = true
      if (star < end) {
        this.stringAnswer = this.stringAnswer + data[star]
        star++
        if (this.stringAnswer) {
          if (this.editOldItem) {
            // 编辑旧问题数据 editOldIndex
            // console.log('editOldItem editOldIndex', that.editOldItem)
            nowAnswer = this.mainDialogList[this.editOldIndex]
            // console.log('nowAnswer', nowAnswer)
            // 更具id查找是哪一项问题
            questionList = nowAnswer.question_answer
            // 找到问题项中的答案项
            answerList = questionList[questionList.length - 1].answer_data
            // 转换为字符串
            answerObj = { textName: this.stringAnswer }
            answerList[answerList.length - 1].answer_content = JSON.stringify(answerObj)
            answerList[answerList.length - 1].answer_type = 1
          } else {
            // 获取新数据
            this.addDialogItem(this.stringQuestion, '', 1)
            //   找到是哪一个问题 --更具id查找是哪一项
            nowAnswer = this.mainDialogList[this.mainDialogList.length - 1]
            questionList = nowAnswer.question_answer
            // 找到是问题中的哪一个答案 --待修改为根据id查找是答案的哪一项
            answerList = questionList[questionList.length - 1].answer_data
            // 转换为字符串
            answerObj = { textName: this.stringAnswer }
            answerList[answerList.length - 1].answer_content = JSON.stringify(answerObj)
            this.fnscrollTop()
          }
          this.fnDebounce()
        }
        setTimeout(() => {
          this.addInSequence(data, star, end)
        }, 40)
      } else {
        // this.fnDebounce()
      }
    }
  },
  created () {
    this.initWebSocket()
    this.fnGetQuestionList()
    this.getQuestionTimer = setInterval(() => {
      this.fnGetQuestionList()
    }, 30000)
  },
  mounted () {
    // console.log('$route.query.id', this.$route.query.id)
    this.originDialog = this.$route.params
    console.log(' this.originDialog', this.originDialog)
    if (this.$route.query.id) {
      // 历史对话
      this.isFirstNew = false
      this.dialogId = this.$route.query.id
      // 获取记录
      this.fnGetDialogDetial(this.$route.query.id)
      this.fngetBrainHelpDetail(this.$route.query.staff_id)
    } else {
      // 新对话
      this.isFirstNew = true
      this.dialogId = this.$route.params.newRoomId
      this.newQuestionId = this.$route.params.firstQuestionId
      this.inputQuestionType = this.originDialog.questionType
      const firstObj = {
        question: this.originDialog.firstMsg,
        questionFile: this.originDialog.firstDetial.question_attachment,
        questionFileGuuid: this.originDialog.firstDetial.question_file_guuid
      }
      // 等待ws链接
      setTimeout(() => {
        this.decisionProblem(firstObj, '', this.originDialog.questionType)
      }, 300)
    }
    if (this.$route.params.id) {
      // 历史对话
      this.isFirstNew = false
      // 获取记录
      this.fnGetDialogDetial(this.$route.params.id)
    }
  },
  beforeRouteLeave (to, from, next) {
    // this.chatWebSocket && this.chatWebSocket.close(1000, '切换对话')
    // this.reLoadData()
    this.chatWebSocket.close(1000, '切换对话')
    this.needReconnet = false
    // this.chatWebSocket = null
    this.$nextTick(() => {
      next()
    })
  },
  destroyed () {
    // this.chatWebSocket && this.chatWebSocket.onclose()
    // this.reLoadData()
    this.chatWebSocket && this.chatWebSocket.close(1000, '切换对话')
    clearInterval(this.getQuestionTimer)
  }

}
</script>

<style scoped lang="less">
.commonChatContent {
  width: 100%;
  height: 100%;
  background-color: #fff;
  // padding: 0 120px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .mainDialogBox {
    width: 100%;
    height: 68%;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    // background-color: orange;
    .brianDetialBox{
      margin-top: 14px;
      width: 76%;
      height: 120px;
      background-image: url('~@/assets/img/juewu/brainChatBgp.png');
      background-size: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 15px;
      box-sizing: border-box;
      .leftPhoto{
        width: 80px;
        height: 80px;
        background-color: #fff;
        box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.2);
        border-radius: 10px 10px 10px 10px;
        border: 2px solid #FFFFFF;
                box-sizing: border-box;
        img{
          width: 100%;
          border-radius: 10px 10px 10px 10px;
        }
      }
      .rightDetial{
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        margin-left: 10px;
        .topName{
          text-align: left;
          width: 1050px;
          font-size: 24px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 500;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .bottomDescribe{
          text-align: left;
          width: 1050px;
          font-size: 16px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          // 只要超过宽度就换行，不论中文还是英文
          word-break: break-all;
          //最多展示两行
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          //根据样式设置
          line-height: 1.2em;
          max-height: 50px;
        }
      }
    }
    .dialogItem {
      width: 76%;
      margin-top: 15px;
    }
  }
  .clueBox{
    width: 77%;
    text-align: left;
    height: 5%;
    line-height: 35px;
    box-shadow: 0px -5px 10px 10px #fff;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .innerclueBox{
      display: flex;
      align-items: center;
    }
    .clueItem{
      max-width: 1100px;
      margin-left: 10px;
      padding:  5px 15px;
      box-sizing: border-box;
      height: 35px;
      line-height: 25px;
      font-size: 14px;
      font-family: Source Han Sans CN, Source Han Sans CN;
      font-weight: 400;
      color: #4D4D4D;
      background: #F2F2F2;
      border-radius: 5px 5px 5px 5px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .stopAnsweringBox {
    // height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    .stopButton {
      width: 120px;
      height: 30px;
      background: #FFFFFF;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #FF4258;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FF4258;
    }
  }

  .bottomInput {
    // !!!!应该需要全局定义的输入组件样式,以固定下部样式不变
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 78%;
    height: 25%;
    padding-bottom: 25px !important;
    box-sizing: border-box;
  }
}
</style>
